@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600|Roboto:300,400');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #505050;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button:focus {
  outline: none !important;
}
a {
  text-decoration: none;
  color: currentColor;
}
a:hover {
  text-decoration: underline;
}
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.page-container {
  margin: 60px 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button,
label,
input,
textarea {
  font-family: 'Montserrat', sans-serif;
  transition: font-size 300ms ease-out;
}
